import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import tw, { css } from 'twin.macro'
import Button from '../components/button'
import Heading from '../components/heading'
import Layout from '../components/layout'
import Text, { typography } from '../components/text'
import { global } from '../styles/global'
import { format } from '../utils/date'

const ArticleTemplate = ({ data: { article, news }, location }) => {
  return (
    <Layout pageTags={article.seoMetaTags} noIndex={article.noIndex} location={location}>
      <div>
        <header css={tw`bg-secondary-500 bg-opacity-5 pt-8 pb-20 lg:py-32`}>
          <div css={[global`layout.container`, global`layout.grid`]}>
            <div css={tw`col-span-4 md:col-span-8 lg:col-span-2 xl:col-span-3`}>
              <Button
                type="primary-reverse"
                size="sm"
                label={news.backLabel}
                link="/news"
                theme="transparent-transparent"
                style={css`
                  ${tw`mb-10 lg:(-mt-3 mb-0)`}
                  span {
                    ${tw`m-0`}
                  }
                  svg {
                    ${tw`mr-3`}
                  }
                `}
              />
            </div>
            <div
              css={tw`flex flex-col items-start col-span-4 pl-6 md:col-span-8 lg:(pl-0 col-span-8) xl:col-span-10`}
            >
              <time
                css={[
                  global`typography.subtitle`,
                  tw`block font-bold text-primary-500 text-opacity-47 mb-10 lg:mb-16`,
                ]}
              >
                {article.date != null && format(article.date, 'dd MMMM yyyy')}
              </time>
              <Heading
                content={article.headline}
                headingType="h2"
                style={tw`font-bold text-primary-500`}
              />
              {article.subheading && (
                <Heading content={article.subheading} headingType="h5" style={tw`mt-6`} />
              )}
            </div>
          </div>
        </header>
        <section
          css={[global`layout.container`, global`layout.grid`, tw`pt-20 pb-24 lg:(pt-32 pb-40)`]}
        >
          <Text
            content={article.contentNode}
            style={[
              typography,
              global`typography.body-lg`,
              tw`col-span-4 md:col-span-8 lg:(col-start-3 col-end-11) xl:(col-start-4 col-end-12)`,
            ]}
          />
          {article.toggleFurtherInfo1 && (
            <Text
              content={article.furtherInfo1Node}
              style={[
                typography,
                global`typography.body-lg`,
                tw`text-primary-500 col-span-4 md:col-span-8 lg:(col-start-3 col-end-11) xl:(col-start-4 col-end-12)`,
              ]}
            />
          )}
          {article.toggleFurtherInfo2 && (
            <Text
              content={article.furtherInfo2Node}
              style={[
                typography,
                global`typography.body-lg`,
                tw`text-primary-500 col-span-4 md:col-span-8 lg:(col-start-3 col-end-11) xl:(col-start-4 col-end-12)`,
              ]}
            />
          )}
          <Text
            content={article.moreContentNode}
            style={[
              typography,
              global`typography.body-lg`,
              tw`text-primary-500 col-span-4 md:col-span-8 lg:(col-start-3 col-end-11) xl:(col-start-4 col-end-12)`,
            ]}
          />
        </section>
      </div>
    </Layout>
  )
}

ArticleTemplate.propTypes = {
  data: PropTypes.shape({
    article: PropTypes.object.isRequired,
    news: PropTypes.object.isRequired,
  }),
  location: PropTypes.object,
}

export default ArticleTemplate

export const query = graphql`
  query($slug: String!, $locale: String!) {
    article: datoCmsPressRelease(slug: { eq: $slug }, locale: { eq: $locale }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      noIndex
      headline
      subheading
      date
      toggleFurtherInfo1
      toggleFurtherInfo2
      furtherInfo1Node {
        childMarkdownRemark {
          html
        }
      }
      furtherInfo2Node {
        childMarkdownRemark {
          html
        }
      }
      moreContentNode {
        childMarkdownRemark {
          html
        }
      }
      contentNode {
        childMarkdownRemark {
          html
        }
      }
    }
    news: datoCmsKrNews(locale: { eq: $locale }) {
      backLabel
    }
  }
`
